//programming languages
import c from "../images/progLang/c.svg"
import cpp from "../images/progLang/cpp.svg"
import csharp from "../images/progLang/csharp.svg"
import css from "../images/progLang/css.svg"
import scss from "../images/progLang/scss.svg"
import html from "../images/progLang/html.svg"
import javascript from "../images/progLang/javascript.svg"
import matlab from "../images/progLang/matlab.svg"
import python from "../images/progLang/python.svg"

//frameworks
import dotnet from "../images/frameworks/dotnet.svg"
import electron from "../images/frameworks/electron.svg"
import firebase from "../images/frameworks/firebase.svg"
import gatsby from "../images/frameworks/gatsby.svg"
import nodejs from "../images/frameworks/nodejs.svg"
import react from "../images/frameworks/react.svg"

//version control
import git from "../images/versionControl/git.svg"
import github from "../images/versionControl/github-mark.svg"

const logos = {
    progLang: {
        c: c,
        cpp: cpp,
        csharp: csharp,
        css: css,
        scss: scss,
        html: html,
        javascript: javascript,
        matlab: matlab,
        python: python,
    },
    frameworks: {
        dotnet: dotnet,
        electron: electron,
        firebase: firebase,
        gatsby: gatsby,
        nodejs: nodejs,
        react: react,
    },
    versionControl: {
        git: git,
        github: github,
    }
}

export default logos